import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts/index';
import SEO from '../components/SEO';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-not-found">
        <SEO title="Page Not Found" />
        <div className="intro mt-5 mt-lg-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>The page you're looking for can't be found.</h2>
                <br/>
                <Link to="/">Go back to homepage</Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;